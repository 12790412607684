import { useState, createContext, useContext, Fragment } from 'react';
import { Link } from '@inertiajs/react';
import { Transition } from '@headlessui/react';

import { cn } from '@/lib/utils';

const DropDownContext = createContext();

const Dropdown = ({ children }) => {
	const [open, setOpen] = useState(false);

	const toggleOpen = () => {
		setOpen((previousState) => !previousState);
	};

	return (
		<DropDownContext.Provider value={{ open, setOpen, toggleOpen }}>
			<div className="relative">{children}</div>
		</DropDownContext.Provider>
	);
};

const Trigger = ({ children, className }) => {
	const { open, setOpen, toggleOpen } = useContext(DropDownContext);

	return (
		<div className={cn('h-12', className && className)}>
			{/* Note: add customed class "avatar-dropdown-open" to add the custom style when the dropdown is open */}
			<div onClick={toggleOpen} className={open ? 'avatar-dropdown-open' : undefined}>
				{children}
			</div>

			{open && <div className="fixed inset-0 z-40" onClick={() => setOpen(false)}></div>}
		</div>
	);
};

const Content = ({ align = 'right', width = '262', contentClasses = 'bg-white', wrapClassName, children }) => {
	const { open, setOpen } = useContext(DropDownContext);

	let alignmentClasses = 'origin-top';

	if (align === 'left') {
		alignmentClasses = 'origin-top-left left-0';
	} else if (align === 'right') {
		alignmentClasses = 'origin-top-right right-0 top-eis-61';
	} else if (align === 'center') {
		alignmentClasses = 'origin-top-left left-1/2 -translate-x-1/2 top-eis-61';
	}

	let widthClasses = '';

	if (width === '262') {
		widthClasses = 'w-eis-262';
	}

	return (
		<>
			<Transition
				as={Fragment}
				show={open}
				enter="transition ease-out duration-200"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<div
					className={`absolute z-50 rounded-md text-eis-black text-14xs leading-eis-1.29 shadow-drop-shadow translate-y-2 ${alignmentClasses} ${widthClasses} ${wrapClassName}`}
					onClick={() => setOpen(false)}
				>
					<div className={`rounded-md border border-eis-light-blue-1 ` + contentClasses}>{children}</div>
				</div>
			</Transition>
		</>
	);
};

const getAllSubMenuItems = (items) => {
	let allSubMenuItemss = [];

	for (let x of items) {
		allSubMenuItemss.push(
			// Note: submenu item
			<Dropdown.Link href={route(x.href)} className="p-2 px-3">
				{x.name}
			</Dropdown.Link>
		);
	}

	return allSubMenuItemss;
};

const DropdownLink = ({ className = '', children, subMenu, ...props }) => {
	const [isShown, setIsShown] = useState(false);

	return (
		<div
			className={cn(
				'relative',
				// Note: for when has subMenu
				subMenu && "after:absolute after:top-0 after:-left-2 after:w-2 after:h-full after:content-[''] after:bg-transparent"
			)}
			onMouseEnter={() => setIsShown(true)}
			onMouseLeave={() => setIsShown(false)}
		>
			<Link
				{...props}
				className={
					'block w-full px-2 py-1.5 text-left hover:bg-eis-light-blue hover:rounded-md focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out flex gap-2 items-center ' +
					className
				}
			>
				{children}
			</Link>

			{subMenu && isShown && (
				<div className="absolute top-0 p-1 bg-white border rounded-md -left-eis-204 border-eis-light-blue-1 w-eis-196 shadow-drop-shadow">
					{getAllSubMenuItems(subMenu)}
				</div>
			)}
		</div>
	);
};

Dropdown.Trigger = Trigger;
Dropdown.Content = Content;
Dropdown.Link = DropdownLink;

export default Dropdown;
